@import '~prismjs/themes/prism-dark.css';
@import './variables.less';

html {
	scroll-behavior: smooth;
	font-size: 10px;
}

body {
	// font-size: 16px;
	padding: 0px;
	margin: 0px;
	font-family: @font-family;
	position: relative;
	background-color: #f4f4f4;
}

h1,
h2,
h3,
h4 {
	margin-block-start: 0em;
	margin-block-end: 0em;
}

h1 {
	font-family: @font-family;
	line-height: 25px;
	font-family: 'OpenSans-Semibold';
}

h2 {
	font-family: @font-family;
	line-height: 30px;
	font-weight: normal;
}

h3 {
	font-family: @font-family;
	line-height: 24px;
	font-family: 'OpenSans-Semibold';
}

h4 {
	font-family: @font-family;
	font-size: 0.875rem;
	line-height: 22px;
	font-weight: 600;
}

p {
	display: block;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

ul,
dl {
	margin-block-end: 0em;
	margin-block-start: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
	padding-inline-end: 0px;
}

.underline {
	text-decoration: underline;
}

router-outlet {
	padding: 0px !important;
}

.page-main {
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
	height: 100%;
}

.page-section {
	display: flex;
	position: relative;
	flex-direction: column;
	font-size: 14px;
	margin-bottom: 100px;

	&:first-of-type {
		padding-top: 75px;
	}

	&:last-of-type {
		height: calc(100vh - 175px);
	}

	.page-section-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		margin-bottom: 5px;
		font-size: 24px;
		color: black;

		svg {
			margin-left: 10px;
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: all 0.15s linear;
		}

		&:hover {
			text-decoration: underline;

			svg {
				opacity: 1;
			}
		}
	}

	.page-section-no-link-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 5px;
		font-size: 24px;
		color: black;
	}

	.page-sub-section {
		display: flex;
		font-size: 16px;
		flex-direction: column;
		margin-top: 10px;
		margin-bottom: 30px;

		&:last-of-type {
			margin-bottom: 0px;
		}

		h4 {
			color: black;
		}

		code {
			color: black;
		}

		.page-api-detail {
			color: black;
		}

		.page-sub-section-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			margin-bottom: 5px;
			font-size: 20px;
			color: black;

			svg {
				margin-left: 10px;
				width: 20px;
				height: 20px;
				opacity: 0;
				transition: all 0.15s linear;
			}

			&:hover {
				text-decoration: underline;

				svg {
					opacity: 1;
				}
			}
		}

		.page-section-title-empty {
			color: rgba(0, 0, 0, 0.5);
		}
	}

	&#related,
	#dev,
	#dev-started {
		color: black;
	}

	.page-section-content {
		color: black;

		a {
			color: blue;
		}
	}

	.page-section-title-empty {
		color: rgba(0, 0, 0, 0.5);
	}
}

.page-section-content {
	padding: 5px 0px;
	font-size: 16px;
	max-width: unset;
	color: black;
	width: auto;
	max-width: 600px;
}

.page-section-content-split {
	display: flex;
	flex-direction: row;
}

.placeholder-image {
	border: 2px dashed lightgrey;
}

.page-section-list {
	font-size: 16px;
	list-style: none;
	color: black;

	a {
		color: blue;
	}

	.page-section-list-title {
		font-family: 'OpenSans-Semibold';
		font-family: 'Open Sans', Arial, sans-serif;
		font-size: 1.6rem;
		color: black;
		padding: 5px 0px;
	}

	.page-section-list-item {
		padding: 5px 0px 10px 0px;
		max-width: unset;
		width: auto;
		line-height: 24px;

		&:first-of-type {
			padding-top: 0px;
		}

		span.underline {
			font-size: 16px !important;
		}
	}
}

.page-section-do-list-title {
	font-family: 'OpenSans-Semibold';
	font-size: 16px;
}

.page-section-do-list {
	list-style: disc;
	margin-left: 20px;
}

.page-section-sub-do-list {
	list-style: circle;
	margin-left: 20px;
	margin-top: 12px;
}

.page-section-bulleted-list {
	list-style: disc;
	margin-left: 20px;
}

.theme--darkmode,
.theme--default {

	.page-section-list a,
	.page-api-detail a {
		color: blue;
	}
}

.page-inline-box {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 10px;
	width: 400px;
	color: black;
}

.page-kendo-link {
	border: 1px solid;
	padding: 0px 5px 0px 2px;
	border-radius: 5px;
	background-color: white;
	border-color: lightgrey;

	svg {
		margin-right: 2px;
		display: inline-block;
		vertical-align: middle;
	}
}

.page-general-link {
	border: 1px solid;
	padding: 0px 5px 0px 5px;
	border-radius: 5px;
	background-color: white;
	border-color: lightgrey;
}

.page-api-detail {
	z-index: 2;
	margin: 10px 0px 0px 0px;
	font-size: 16px;

	&:first-of-type {
		margin: 0px;
	}
}

.page-api-list-item-span {
	cursor: text;
	border-bottom: 1px solid lightgrey;
	padding-bottom: 5px;
	margin-bottom: 5px;
	display: flex;
}

.page-api-summary {
	font-family: 'OpenSans-Semibold';
	cursor: pointer;
}

.page-api-list {
	width: 700px;

	.page-api-list-item {
		padding: 5px;
		cursor: default;
		border: 1px solid lightgrey;
		border-radius: 5px;
		padding: 10px;
		margin: 10px 0px 10px 15px;
		background-color: white;

		details {
			font-size: 14px !important;
		}

		p {
			cursor: text;
			font-size: 14px !important;
		}
	}
}

.page-pre-code {
	background-color: #292929;
	color: white;
	font-size: 14px;
	border-radius: 5px;
	padding: 0px 20px;
	max-width: 800px;
	overflow: auto;
}

.page-nav-list {
	display: flex;
	flex-direction: column;
	font-size: 15px;
	padding-top: 60px;

	.page-nav-list-item {
		display: flex;
		padding: 5px 15px;
		border-left: none;

		&:hover {
			cursor: pointer;
			background-color: lightgray;
		}
	}

	.page-nav-sub-list-container {
		padding: 0px;

		&:hover {
			background-color: #f4f4f4;
		}

		.page-nav-sub-list-title {
			font-size: 15px;
		}

		.page-nav-sub-list-item {
			display: flex;

			padding: 5px 15px 5px 30px;

			&:hover {
				cursor: pointer;
				background-color: lightgray;
				//border-left: 1px solid #137cd5;
			}
		}
	}

	.page-nav-list-title {
		display: flex;
		padding: 5px 19px;
		border-left: none;
		font-family: 'OpenSans-Semibold';
		cursor: default;
	}
}

.nav-sub-list-link {
	text-decoration: none;

	&:focus {
		outline: none;
	}
}

.page-section-remove-notice {
	background-color: red;
	color: white;
	padding: 10px;

	a,
	a:visited {
		color: white;
	}
}

.page-overview-link {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;

	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 10px;
	width: auto;
	max-width: 400px;
	color: black;

	a {
		margin-right: 10px;
		color: blue !important;

		svg {
			margin-right: 5px;
		}

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&.kendo-link {
		flex-direction: column;
		align-items: flex-start;

		div {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.redirect {
				padding: 0px 4px;
			}

			&:first-of-type {
				width: 100%;
				margin-bottom: 10px;
				border-bottom: 1px solid lightgrey;
				padding-bottom: 10px;
			}
		}
	}
}

.example-container {
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 10px;
	margin-top: 10px;
	flex-direction: column;
}

.example-container {
	display: flex;

	.page-live-header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.page-live-example-select {
		display: flex;
		padding: 1px 1px 1px 5px;
		border: 1px solid #a8afb5;
		color: #292929;
		font-size: 14px;
		width: 258px;
		height: 34px;
		background-color: #f4f4f4 !important;
		border-radius: 5px;
	}

	.page-live-theme-select {
		margin-left: 20px;
		display: flex;
		padding: 1px 1px 1px 5px;
		border: 1px solid #a8afb5;
		color: #292929;
		font-size: 14px;
		width: 150px;
		height: 34px;
		background-color: #f4f4f4 !important;
		border-radius: 5px;
	}

	.page-live-example-controls {
		margin-top: 16px;
		width: 100%;

		.button {
			height: 34px;
		}
	}

	.page-live-example-content {
		padding: 0px 10px 10px 10px;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		min-height: 200px;
	}
}

.page-style-details {
	margin: 10px 0px 0px 0px;
	font-size: 16px;
	width: 700px;

	&:first-of-type {
		margin: 0px;
	}
}

.page-style-summary {
	font-family: 'OpenSans-Semibold';
	cursor: pointer;

	code {
		font-weight: normal;
	}
}

.page-style-list-title {
	margin-left: 15px;
	margin-top: 20px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	align-items: baseline;

	code {
		margin-left: 5px;
	}

	&:first-of-type {
		margin-top: 10px;
	}
}

.page-style-list {
	cursor: default;
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 10px;
	margin: 10px 0px 10px 15px;
	background-color: white;
	display: flex;
	flex-direction: column;

	.page-style-list-item {
		display: flex;
		flex-direction: row;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid lightgrey;

		&:last-of-type {
			padding-bottom: 0px;
			margin-bottom: 0px;
			border-bottom: none;
		}

		>span {
			width: 33%;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.page-style-list-item-color {
			margin: -5px 0px;
		}

		&.page-style-list-item-header {
			font-family: 'OpenSans-Semibold';

			span {
				font-size: 16px;
			}

			&:last-of-type {
				padding-bottom: 10px;
				margin-bottom: 10px;
				border-bottom: 1px solid lightgrey;
			}
		}
	}
}

.theme--darkmode {
	.example-container-dark {
		background-color: #081826 !important;
	}
}

.page-variation-container {
	display: flex;
	margin-top: 10px;
}

.page-related-list-title {
	font-family: 'OpenSans-Semibold';
	margin-top: 30px;
	font-size: 16px;

	&:first-of-type {
		margin-top: 0px;
	}
}

.page-related-list {
	margin-top: 0px;
}

.page-related-list-item {
	padding: 5px 0px;
}

.page-history-list {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	margin-top: 5px;
	list-style-type: disc;
	color: black;
}

.page-history-list-item {
	list-style-type: disc;
	font-size: 16px;
	padding: 5px 0px 10px 0px;

	.page-history-list-item-title {
		font-family: 'OpenSans-Semibold';
		margin-right: 10px;
	}

	.page-history-list-item-link {
		margin-right: 5px;
	}
}

#dynamic-container-host {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 999;
}

ul.bulleted-list,
ol.numbered-list {
	margin: 10px 0 10px 35px;

	ul.bulleted-list {
		margin-left: 15px;
	}
}

ul.bulleted-list li {
	list-style-type: disc;
}

ol.numbered-list {
	list-style-type: decimal;
}

// @media (min-width: 1024px) {

// .page-nav-list {
// 	position: fixed;
// 	right: 15px;
// 	top: 25px;
// 	width: 185px;
// 	margin-top: 49px;
// 	font-size: 14px;
// 	padding-top: 0px;
// 	border-left: 1px solid lightgrey;
// 	height: calc(100vh - 85px);
// 	overflow-y: auto;

// 	.page-nav-list-title {
// 		font-size: 16px;
// 	}

// 	.page-nav-sub-list {
// 		margin-left: -4px;
// 	}

// 	.page-nav-list-item {
// 		border-left: 4px solid transparent;
// 		font-family: 'OpenSans-Semibold';

// 		&.visible {
// 			border-left: 4px solid #137cd5 !important;
// 		}
// 	}

// 	.page-nav-sub-list-container {
// 		display: flex;
// 		flex-direction: column;

// 		.page-nav-sub-list-title {
// 			border-left: 0px;
// 			padding-left: 19px;
// 			font-size: 14px;
// 		}

// 		.page-nav-sub-list-item {
// 			margin-left: 4px;
// 			border-left: 4px solid transparent;
// 			font-weight: normal;

// 			&.visible {
// 				border-left: 4px solid #137cd5;
// 			}
// 		}
// 	}
// }
//}

.page-section-list {
	.page-section-list-item-sub-title {
		font-family: 'OpenSans-Semibold';
		display: flex;
		font-size: 16px;
	}

	// .page-section-list-item:first-of-type {
	// 	.page-section-list-item-sub-title {
	// 		margin-top: -5px;
	// 	}
	// }
}

.theme--darkmode .example-container {
	background-color: #15293a;
}

.migration-container {
	display: flex;
	flex-direction: column;
	padding: 30px 40px;
	margin-top: 20px;
	background-color: lightgrey;

	.migration-title {
		font-size: 20px;
		font-family: 'OpenSans-Semibold';
	}

	// .migration-list {
	// }

	.migration-list-item {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.migration-list-item-title {
		font-size: 16px;
		font-family: 'OpenSans-Semibold';
	}
}

.page-section-comparison-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	color: #000;

	.page-section-comparison-list-item {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 20px;

		&.comparison-do {
			.page-section-comparison-header {
				border: 1px solid lightgrey;
				border-bottom: 4px solid #006e18;
				background-color: rgba(0, 110, 24, 0.2);

				.k-svg-icon {
					height: 25px;
					width: 40px;
				}

				svg {
					* {
						fill: #006e18;
					}
				}
			}
		}

		&.comparison-do-not {
			.page-section-comparison-header {
				border: 1px solid lightgrey;
				border-bottom: 4px solid #c1001b;
				background-color: rgba(193, 0, 27, 0.2);

				.k-svg-icon {
					height: 25px;
					width: 35px;
				}

				svg {
					* {
						fill: #c1001b;
					}
				}
			}
		}

		.page-section-comparison-header {
			padding: 10px;
			border-radius: 5px 5px 0px 0px;
			display: flex;
			flex-direction: row;
			justify-content: left;
			align-items: center;
			font-family: 'OpenSans-Semibold';

			.k-svg-icon {
				height: 25px;
				width: 35px;
			}

			svg {
				margin-right: 10px;
			}
		}

		.page-section-comparison-body {
			padding: 20px 12px;
			border: 1px solid lightgrey;
			border-top: none;
			border-radius: 0px 0px 5px 5px;
			background-color: white;
			display: flex;
			flex-direction: column;

			.comparison-body-text-list {
				padding-left: 20px;
				list-style-type: none;

				.comparison-body-text-list-item {
					font-size: 16px;
					margin-top: 15px;
					list-style: disc;

					&:first-of-type {
						margin-top: 0px;
					}
				}
			}
		}
	}
}

.guidance-image {
	max-width: 100%;
}

.checkbox-help-tooltip {
	.k-popup {
		position: relative;
	}
}

.kendo-chart-tooltip {
	min-width: revert !important;
	position: relative !important;
}

.resource-list-item {
	display: flex;
	flex-direction: column;

	.resource-source {
		font-size: 14px;
		line-height: 18px;
	}
}

.redlines-container {
	background-color: white;
	border: 1px solid lightgrey;
	border-radius: 5px;
	overflow: hidden;
	width: fit-content;
}

.redlines-caption {
	font-size: 12px;
}

iframe.redlines {
	pointer-events: none;
	border: none;
}

.code-example-tabstrip {
	.k-tabstrip-content,
	.k-tabstrip>.k-content {
		background-color: var(--white);
		border-color: var(--ash);
		color: var(--navy-500);
	}

	.k-tabstrip-items-wrapper {
		border-color: var(--ash);
	}

	.k-tabstrip-items-wrapper .k-item {
		border-left-color: var(--ash);
		border-top-color: var(--ash);
		color: var(--navy-500);
		background-color: var(--white);

		&:last-child {
			border-right-color: var(--ash);
		}
	}

	.k-tabstrip-items-wrapper .k-item.k-focus,
	.k-tabstrip-items-wrapper .k-item:focus {
		outline-color: var(--orange-700);
	}

	.k-tabstrip-items-wrapper .k-item.k-hover,
	.k-tabstrip-items-wrapper .k-item:hover {
		color: var(--orange-700);
	}

	.k-tabstrip-items-wrapper .k-item.k-active,
	.k-tabstrip-items-wrapper .k-item.k-selected,
	.k-tabstrip-items-wrapper .k-item:active {
		background-color: var(--white);
		border-color: var(--ash);
		border-top-color: var(--blue-100);
		color: var(--navy-500);

		&:last-child {
			border-right-color: var(--ash);
		}
	}

	.k-tabstrip-content.k-focus,
	.k-tabstrip-content:focus,
	.k-tabstrip>.k-content.k-focus,
	.k-tabstrip>.k-content:focus {
		outline-color: var(--orange-700);
	}
}

@media (min-width: 800px) {
	.page-main {
		width: calc(100vw - 300px - 100px);
		padding-left: 300px;
		padding-right: 50px;
	}

	.page-section-list {
		.page-section-list-item {
			max-width: 600px;
			width: auto;
		}
	}
}

@media (min-width: 1024px) {
	.page-main {
		width: calc(100vw - 300px - 249px);
		padding-left: 300px;
		padding-right: 229px;
	}

	.page-section-list {
		.page-section-list-item {
			max-width: 600px;
			width: auto;
		}
	}

	.guidance-image {
		max-width: 700px;
		width: auto;
	}

	.page-section-comparison-list {
		display: flex;
		flex-direction: row;
		margin-top: -20px;
		margin-bottom: 25px;
		width: 700px;

		.page-section-comparison-list-item {
			display: flex;
			flex-direction: column;
			width: 100%;

			&:first-of-type {
				margin-right: 20px;
			}
		}
	}
}